:root {
  --wrapperCard: calc(var(--100vh) - 100px);
  --storiesHeight: 110px;
  --searchHeight: 70px;
}
.contianerCardsWrapper {
  scroll-snap-type: y mandatory;
  overflow: hidden;
  overflow-y: auto;
  height: var(--100vh);
  /* background-color: #f1f2f4; */
}

.contianerCardsWrapper > div {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.FirstCard {
  height: var(--100vh);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  overflow: hidden;
  max-width: 700px !important;
  margin: auto;
}

.FistCardWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3px;
  margin: auto;
  min-height: var(--wrapperCard);
}

.StoriesSection {
  /* height: calc((var(--wrapperCard) / 100) * 18); */
  padding: 0 0px;
  height: var(--storiesHeight);
}

.StoriesSection img {
  height: 90px;
}

.SearchSection {
  /* height: calc((var(--wrapperCard) / 100) * 10); */
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  max-height: var(--searchHeight);
}

.CategoriesSection {
  /* height: calc((var(--wrapperCard) / 100) * 62); */
  padding: 0 10px;
}

.CategoriesSection .BigImages img {
  background-color: #f9f8f7;
  height: 200px;
  object-fit: cover;
  border-radius: 15px !important;
}
.searchbox {
  margin: 3px;
}
.text-icon {
  position: absolute;
  top: 12%;
  left: 2%;
}
