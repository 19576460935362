.loaderDiv {
  height: 40vh;
  display: flex;
  justify-content: center; /* Align items horizontally to center */
  align-items: center;
}
.loaderText {
  margin-bottom: 80px;
}
.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  position: absolute;
  /* top: 50%;
  left: 50%; */
  transform: translate(-50%, -50%);
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.badgeButtons {
  position: absolute;
  bottom: 1px;
  right: 0;
  width: fit-content;
}

.categoriesSection {
  height: 80vh;
  overflow: hidden;
  overflow-y: auto;
}
.text-danger {
  font-size: medium;
  text-align: center;
  margin-left: 30px;
}
