.imgCont {
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageText {
  position: absolute;
  color: black;
  text-align: center;
  font-size: larger;
  padding: 5px 10px;
}
