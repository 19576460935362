.submitProfile {
  background-color: #1e74fd;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 17px;
  margin-top: 17px;
  padding: 5px 15px;
}
.submitProfile:disabled {
  background-color: #ccc; /* Light grey background */
  color: #666; /* Dark grey text */
  cursor: not-allowed; /* Not-allowed cursor */
}
