.timeAgo {
    font-size: 10px;
    color: rgb(179, 178, 178);
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

.listview>li header {
    color: rgb(124, 123, 123);
    display: flex;
    align-items: center;
    gap: 5px;
}

.searchSection {
    height: 55px;
}

.UsersChatsListView {
    height: calc(100vh - var(--header-height) - 55px);
    overflow-x: hidden;
    overflow-y: auto;
}

/* width */
.UsersChatsListView::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.UsersChatsListView::-webkit-scrollbar-track {
    background: #d1d0d0;
}

/* Handle */
.UsersChatsListView::-webkit-scrollbar-thumb {
    background: #24265d;
}

/* Handle on hover */
.UsersChatsListView::-webkit-scrollbar-thumb:hover {
    background: #000;
}

.UsersChatsListView .item.active {
    background-color: antiquewhite;
}

.UsersChatsListView .item:hover {
    background-color: rgb(224, 226, 226);
}

.image-listview>li .item .image {
    object-fit: contain;
    border: 1px solid #f3eeee;
}