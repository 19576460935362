.videoContainer {
  position: relative;
  display: flex;

  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100vh;
  z-index: -1;
  backdrop-filter: blur(30px);
}
.closeBtn {
  font-size: 70px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: none;
  margin: 10px 10px;
  padding: 0px 5px;
  border-radius: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  box-shadow: 0px 0px 2px 1px #675454;
  /* background-color: transparent; */
  color: black;
}

.switchBtn {
  border: none;
  width: 50px;
  height: 40px;
  position: absolute;
  margin: 10px 10px;
  padding: 0px 5px;
  border-radius: 10px;
  right: 100px;
  bottom: 60px;
  z-index: 10;
  box-shadow: 0px 0px 2px 1px #675454;
}
.icon {
  margin: 10px 10px;
}
.div1 {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.frame {
  position: absolute;
  /* top: 25%;
  left: 17%; */
  border: 5px solid rgb(35, 112, 236) !important;
  border-radius: 30px !important;
  width: 270px !important;
  height: 270px !important;
  box-sizing: border-box;
  z-index: 30;
  /* animation: aimAnimation 2s infinite alternate; */
}

.frame p {
  background-color: #1E74FD;
  color: #fff;
  text-align: center;
  margin-top: -70px;
  padding: 6px;
  border-radius: 8px;
}

.errorMsg {
  display: flex;
  /* align-items: center; */
  width: 250px;
  background-color: rgb(227, 59, 59);
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 5px;
}
.errorMsg ion-icon {
  font-size: 50px;
  margin-left: 10px;
  color: white;
}
.errorMsg h3 {
  color: white;
  margin: 0;
}
#qr-video {
  width: 100% !important;
  height: 100% !important;
  opacity: 1 !important;
  object-fit: cover;
}
