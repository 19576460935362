h2 {
  text-align: center;
}
/* Imageupload.css */
.wide-block {
  height: 470px;
  border-radius: 20px;
}
.div2 {
  display: flex;
  padding: 20px;
  font-size: 30px;
  width: 260px;
  height: 260px;
  background-color: #ccc;
  border-radius: 15px;
}
.divIcon {
  display: flex;
  position: absolute;
  right: 20%;
  z-index: 3;
}
.divText {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%; /* Ensure the text doesn't overflow its container */
  overflow: hidden; /* Hide overflow text */
  margin: 0 auto; /* Prevent text from wrapping */
  text-align: center;
  align-items: center; /* Align text to center */
  flex-grow: 1;
  position: relative;
}

.divText p {
  font-size: 16px; /* Default font size */
  line-height: 1.5; /* Set line height */
  display: inline-block; /* Ensure center alignment works */
}

/* Media query for smaller screens */
@media screen and (max-width: 600px) {
  .divText p {
    font-size: 14px; /* Decrease font size for smaller screens */
  }
}

.previewContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputCont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* margin-top: 20px; */
}

.inputCont .Placeholder {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  z-index: 0;
}

.inputCont .textarea:focus-visible + .Placeholder {
  display: none;
}

.inputCont .textarea {
  background-color: transparent;
  text-align: center;
  padding: 0;
  z-index: 1;
  width: 100%;
  outline: none;
  border: 0;
  font-size: clamp(12px, 60%, 16px);
  width: 220px;
}

.inputCont .textarea::placeholder {
  color: var(--placeholderColor);
}
.inputCont .textarea:focus-visible,
.inputCont .textarea:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
.inputCont input {
  width: 313px;
}
.subBtn {
  margin-top: 20px;
  width: 329px;
}

.modalContainer {
  height: 65vh;
  padding: 20px;
}

.closeImageUploadOffcanvas {
  position: absolute;
  top: -20px;
  right: -20px;
}


/* HTML: <div class="loader"></div> */
.loader3 {
  width: 20px;
  padding: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: loader3 1s infinite linear;
}
@keyframes loader3 {to{transform: rotate(1turn)}}