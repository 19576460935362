.TourFrame {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1111;
    background-color: rgba(0, 0, 0, 0.562);
    pointer-events: none;
}

.TourFrame .TourSteps {
    height: var(--100vh);
    overflow: hidden;
    pointer-events: none;
}

.TourFrame .TourSteps .Step {
    height: var(--100vh);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    display: none;
    pointer-events: none;
}

.TourFrame .TourSteps .Step.ShowStep {
    display: flex;
}

.TourFrame .TourSteps .Step img {
    width: 60px;
    height: 60px;
    background-color: transparent;
}
.TourFrame .TourSteps .Step p {
    color: #fff;
    text-align: center;
}



.TourFrame .TourSteps .Step button {
    width: fit-content;
    pointer-events: all;
}

.SwipeUpDownCss {
    height: 150px;
    width: 80px;
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 80px;
    overflow: hidden;
}

.SwipeLeftrightCss {
    width: 150px;
    height: 80px;
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 80px;
    overflow: hidden;
}

.TourFrame .TourSteps .Step .SwipeUpDownCss img,
.TourFrame .TourSteps .Step .SwipeLeftrightCss img {
    position: relative;
    z-index: 2;
}

.TourFrame .TourSteps .Step .SwipeUpDownCss img {
    animation: GoUpBg 1000ms ease-in-out infinite alternate-reverse;
}

.TourFrame .TourSteps .Step .SwipeLeftrightCss img {
    animation: GoLeftBg 1000ms ease-in-out infinite alternate-reverse;
}
.SwipeUpCss::before {
    /* height: 80px; */
    height: 100%;
    width: 80px;
    /* border-radius: 50%; */
    content: '';
    position: absolute;
    /* background-color: #D9AFD9; */
    /* animation: GoUpBg 1000ms ease infinite alternate-reverse; */
    background-image: linear-gradient(180deg, #D9AFD9 0%, #97dae136 100%);
    /* opacity: 0.5; */
    z-index: 0;
}

.SwipeLeftCss::before {
    height: 80px;
    width: 100%;
    content: '';
    position: absolute;
    background-image: linear-gradient(90deg, #D9AFD9 0%, #97dae136 100%);
    z-index: 0;
}

@keyframes GoUpBg {
    0% {
        top: 60%;
    }
    100% {
        top: -10%;
    }
}

@keyframes GoLeftBg {
    0% {
        left: 30%;
    }
    100% {
        left: -20%;
    }
}
.moredetailsButton{ 
    position: absolute;
    top: calc(var(--buttonY) + 1px);
}
.moredetailsButton .Button{
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
    width: 180px;
    width: 80vw;
    border-radius: 10px;
    background-color: #1e74fd00;
    border: 1px solid #1E74FD;
    z-index: 0;
    font-size: 18px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    animation: ColorChange 1000ms ease-in-out infinite alternate-reverse;
}
.moredetailsButton .Button ion-icon {
    color: #fff;
}
.moredetailsButton img {
    position: relative;
    z-index: 1;
    animation: Heart 1000ms ease-in-out infinite alternate-reverse;
}

@keyframes Heart {
    0% {
        scale: 1;
    }
    100% {
        scale: 1.2;
    }
}

@keyframes ColorChange {
    0% {
        background-color: #1E74FD;
    }
    100% {
        background-color: #0d4aac;
    }
}