:root {
    --header-height: 56px;
    --componentHeight: 0px;
}

.UserChatView {
    height: calc(100vh - var(--header-height));
    overflow-x: hidden;
    overflow-y: auto;
}

/* width */
.UserChatView::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.UserChatView::-webkit-scrollbar-track {
    background: #d3d2d2;
}

/* Handle */
.UserChatView::-webkit-scrollbar-thumb {
    background: #24265d;
}

/* Handle on hover */
.UserChatView::-webkit-scrollbar-thumb:hover {
    background: rgb(0, 0, 0);
}

.chatHeader {
    display: flex;
    padding: 14px;
    background-color: antiquewhite;
    gap: 10px;
    align-items: center;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
}

.chatHeader .image {
    width: 27px;
    height: 28px;
    object-fit: contain;
}

.chatHeader .in div {
    font-size: 14px;
    font-weight: bold;
}

.chatFooter {
    position: sticky;
}
.message-item.user .content {
    position: relative;
}
.message-item.user .bubble{
    padding-right: 20px;
    width: 100%;
}
.readRecipients {
    position: absolute;
    bottom: 15px;
    right: 3px;
}

.readRecipients > * {
    color: #fff;
    font-size: 12px;
}